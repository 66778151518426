const portalPaths = {
	financial: {
		title: 'Operator Financial Report',
		path: '/internal-portal/operator-financial-report',
		viewReport: 'View Operator Report'
	},
	monitoring: {
		title: 'Operator HR Monitoring',
		path: '/internal-portal/operator-hr-monitoring',
		viewOperator: 'View Operator HR',
		viewOperatorDocument: 'View Operator Documents',
		operatorHrInfo: 'Operator HR info',
		editEmployee: 'Edit Employee info',
		createEmployee: 'Create new operator employee',
		viewDocument: "View Operator Documents"
	},
	lcm: {
		title: 'LCM Internal HR',
		path: '/internal-portal/lcm-internal-hr',
		createEmployee: 'Create new employee',
		editEmployee: 'Edit employee info',
		viewEmployee: 'View employee info'
	},
	management: {
		title: 'User Management',
		path: '/internal-portal/user-management',
		userType: 'User type',
		userInfo: 'User type info',
		userSecurity: 'System Security',
		editUserType: 'Edit user type',
		createUserType: 'Create new user type',
		changePassword: 'Change password'

	},
	activity: {
		title: 'User Activity',
		path: '/internal-portal/user-activity'
	}
}
// state
export const state = () => ({
	menubarInternalPortal: [
		{
			icon: 'mdi-poll',
			title: portalPaths.financial.title,
			to: portalPaths.financial.path,
			display: true,
			code: 'operator_financial_report'
		},
		{
			icon: 'mdi-monitor',
			title: portalPaths.monitoring.title,
			to: portalPaths.monitoring.path,
			display: false,
			code: 'operator_hr_monitoring'
		},
		{
			icon: 'mdi-account-outline',
			title: portalPaths.lcm.title,
			to: portalPaths.lcm.path,
			display: false,
			code: 'lcm_internal_hr'
		},
		{
			icon: 'mdi-account-multiple',
			title: portalPaths.management.title,
			to: portalPaths.management.path,
			display: true,
			code: 'user_management'
		},
		{
			icon: 'mdi-chart-line-variant',
			title: 'User activity',
			to: '/internal-portal/user-activity',
			display: true,
			code: 'user_activity'
		},
	],
	// This below array is for Page Title and Breadcrumbs
	titleAndPathInternalPortal: [
		{
			path: portalPaths.financial.path,
			title: portalPaths.financial.title,
			lists: [
				{
					text: portalPaths.financial.title,
					disabled: true
				}
			]
		},
		{
			path: `${portalPaths.financial.path}/view-report/*`,
			title: portalPaths.financial.viewReport,
			lists: [
				{
					text: portalPaths.financial.title,
					href: portalPaths.financial.path,
					disabled: false,
				},
				{
					text: portalPaths.financial.viewReport,
					disabled: true,
				},
			]
		},
		{
			path: portalPaths.monitoring.path,
			title: portalPaths.monitoring.title,
			lists: [
				{
					text: portalPaths.monitoring.title,
					disabled: true
				}
			]
		},
		{
			path: `${portalPaths.monitoring.path}/view/employee/edit/*`,
			title: portalPaths.monitoring.editEmployee,
			lists: [
				{
					text: portalPaths.monitoring.title,
					href: portalPaths.monitoring.path,
					disabled: false,
				},
				{
					text: portalPaths.monitoring.viewOperator,
					disabled: false,
					href: portalPaths.monitoring.path,
					isGoBack: true
				},
				{
					text: portalPaths.monitoring.editEmployee,
					disabled: true,
				},
			]
		},
		{
			path: `${portalPaths.monitoring.path}/view/employee/create`,
			title: portalPaths.monitoring.createEmployee,
			lists: [
				{
					text: portalPaths.monitoring.title,
					href: portalPaths.monitoring.path,
					disabled: false,
				},
				{
					text: portalPaths.monitoring.viewOperator,
					disabled: false,
					href: portalPaths.monitoring.path,
					isGoBack: true
				},
				{
					text: portalPaths.monitoring.createEmployee,
					disabled: true,
				},
			]
		},
		{
			path: `${portalPaths.monitoring.path}/view/employee/*`,
			title: portalPaths.monitoring.operatorHrInfo,
			lists: [
				{
					text: portalPaths.monitoring.title,
					href: portalPaths.monitoring.path,
					disabled: false,
				},
				{
					text: portalPaths.monitoring.viewOperator,
					disabled: false,
					href: portalPaths.monitoring.path,
					isGoBack: true
				},
				{
					text: portalPaths.monitoring.operatorHrInfo,
					disabled: true,
				},
			]
		},
		{
			path: `${portalPaths.monitoring.path}/view/documents/*`,
			title: portalPaths.monitoring.viewOperatorDocument,
			lists: [
				{
					text: portalPaths.monitoring.title,
					href: portalPaths.monitoring.path,
					disabled: false,
				},
				{
					text: portalPaths.monitoring.viewDocument,
					disabled: true,
				},
			]
		},
		{
			path: `${portalPaths.monitoring.path}/view/*`,
			title: portalPaths.monitoring.viewOperator,
			lists: [
				{
					text: portalPaths.monitoring.title,
					href: portalPaths.monitoring.path,
					disabled: false,
				},
				{
					text: portalPaths.monitoring.viewOperator,
					disabled: true,
				},
			]
		},
		{
			path: portalPaths.lcm.path,
			title: portalPaths.lcm.title,
			lists: [
				{
					text: portalPaths.lcm.title,
					disabled: true,
				},
			]
		},
		{
			path: `${portalPaths.lcm.path}/create`,
			title: portalPaths.lcm.createEmployee,
			lists: [
				{
					text: portalPaths.lcm.title,
					href: portalPaths.lcm.path,
					disabled: false,
				},
				{
					text: portalPaths.lcm.createEmployee,
					disabled: true,
				},
			]
		},
		{
			path: `${portalPaths.lcm.path}/edit/*`,
			title: portalPaths.lcm.editEmployee,
			lists: [
				{
					text: portalPaths.lcm.title,
					href: portalPaths.lcm.path,
					disabled: false,
				},
				{
					text: portalPaths.lcm.editEmployee,
					disabled: true,
				},
			]
		},
		{
			path: `${portalPaths.lcm.path}/detail/*`,
			title: portalPaths.lcm.viewEmployee,
			lists: [
				{
					text: portalPaths.lcm.title,
					href: portalPaths.lcm.path,
					disabled: false,
				},
				{
					text: portalPaths.lcm.viewEmployee,
					disabled: true,
				},
			]
		},
		{
			path: portalPaths.management.path,
			title: portalPaths.management.title,
			lists: [
				{
					text: portalPaths.management.title,
					disabled: true,
				},
			]
		},
		{
			path: `${portalPaths.management.path}/change-password/*`,
			title: portalPaths.management.changePassword,
			lists: [
				{
					text: portalPaths.management.title,
					href: portalPaths.management.path,
					disabled: false,
				},
				{
					text: portalPaths.management.changePassword,
					disabled: true,
				},
			]
		},
		{
			path: `${portalPaths.management.path}/system-security`,
			title: portalPaths.management.userSecurity,
			lists: [
				{
					text: portalPaths.management.title,
					href: portalPaths.management.path,
					disabled: false,
				},
				{
					text: portalPaths.management.userSecurity,
					disabled: true,
				},
			]
		},
		{
			path: `${portalPaths.management.path}/user-type/*`,
			title: portalPaths.management.userType,
			lists: [
				{
					text: portalPaths.management.title,
					href: portalPaths.management.path,
					disabled: false,
				},
				{
					text: portalPaths.management.userType,
					href: `${portalPaths.management.path}/user-type`,
					disabled: false,
				},
				{
					text: portalPaths.management.userType,
					disabled: true,
				},
			]
		},
		{
			path: `${portalPaths.management.path}/user-type`,
			title: portalPaths.management.userType,
			lists: [
				{
					text: portalPaths.management.title,
					href: portalPaths.management.path,
					disabled: false,
				},
				{
					text: portalPaths.management.userType,
					disabled: true,
				},
			]
		},
		{
			path: `${portalPaths.management.path}/user-info`,
			title: portalPaths.management.userInfo,
			lists: [
				{
					text: portalPaths.management.title,
					href: portalPaths.management.path,
					disabled: false,
				},
				{
					text: portalPaths.management.userInfo,
					disabled: true,
				},
			]
		},
		{
			path: `${portalPaths.management.path}/user-info/edit/*`,
			title: portalPaths.management.editUserType,
			lists: [
				{
					text: portalPaths.management.title,
					href: portalPaths.management.path,
					disabled: false,
				},
				{
					text: portalPaths.management.userInfo,
					href: `${portalPaths.management.path}/user-type`,
					disabled: false,
				},
				{
					text: portalPaths.management.editUserType,
					disabled: true,
				},
			]
		},
		{
			path: `${portalPaths.management.path}/user-info/create`,
			title: portalPaths.management.createUserType,
			lists: [
				{
					text: portalPaths.management.title,
					href: portalPaths.management.path,
					disabled: false,
				},
				{
					text: portalPaths.management.userInfo,
					href: `${portalPaths.management.path}/user-type`,
					disabled: false,
				},
				{
					text: portalPaths.management.createUserType,
					disabled: true,
				},
			]
		},
		{
			path: `${portalPaths.management.path}/*`,
			title: portalPaths.management.userInfo,
			lists: [
				{
					text: portalPaths.management.title,
					href: portalPaths.management.path,
					disabled: false,
				},
				{
					text: portalPaths.management.userType,
					href: portalPaths.management.path,
					disabled: false,
				},
				{
					text: portalPaths.management.userInfo,
					disabled: true,
				},
			]
		},
		{
			path: portalPaths.activity.path,
			title: portalPaths.activity.title,
			lists: [
				{
					text: portalPaths.activity.title,
					disabled: true,
				},
			]
		},
	]
})

export const getters = {
	menubarInternalPortalWithPermissions: (state, getters, rootState) => {
		if (rootState.internal['role-permissions'].rolePermissions.length) {
			return state.menubarInternalPortal.map((el) => {
				return {
					...el,
					display: rootState.internal['role-permissions'].rolePermissions.find((permission) => permission.permission_code === el.code).can_view
				}
			})
		}
		return []
	},
}